<template>
  <div class="app-container">
    <nav>
      <div class="tips">
          <!-- <div class="text">客户名称</div> -->
          <el-input v-model="queryValue" placeholder="请输入客户名称"></el-input>
          <el-button @click="initTable(1)" type="primary" class="btn" v-auth='"customerInfo:lookup"'>查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="add" type="primary" class="btn" v-auth='"customerInfo:add"'>+ 新增</el-button>
        <el-button @click="imports" type="primary" class="btn"><i class="iconfont iconicon-03" v-auth='"customerInfo:import"'></i> 导入</el-button>
        <el-button @click="handleExport" type="primary" class="btn"><i class="iconfont iconicon-04" v-auth='"customerInfo:export"'></i> 导出</el-button>
        <el-button @click="selectDel" type="primary" class="btn"><i class="iconfont iconicon-05" v-auth='"customerInfo:delete"'></i> 批量删除</el-button>
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
       <!-- :hideSinglePage='false' :selectionType='true'-->
      <!-- <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' :pageSize='form.rows' :page='form.page' @current-events="getInitList" @size-events='changeSize' class="comp_table" v-if="tableData.length" :pageSizeList='pageSizeList'>
      </comps-table> -->
      <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

    <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config">
      <div class="answer">亲，确认要删除吗？</div>
    </del-dialog>

    <!-- <pdf-dialog :visible='pdfVis' :cancelDialog='pdfCancel'/> -->
   <import-dialog :visible='importVis' :cancelDialog='importCancel'/>
   <!-- <export-dialog :visible='exportVis' :cancelDialog='exportCancel' @selectType="selectType" :confirmDialog='confirmExport'/> -->
   <export-dialog :visible='exportVis' :cancelDialog='exportCancel'  :confirmDialog='confirmExport'/>


  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import { insurancesList, deleteInsurances } from '@/api/insurances'
  // import { findCustomer } from '@/api/invoicing'
  // import { findTerminal, findTerminalList, delTerminal } from '@/api/setInvoice'
  import importDialog from './dialog/imports'
  import exportDialog from './dialog/export'
  import axios from "axios";
  export default {
    components: { delDialog ,compsTable , importDialog , exportDialog, },
    data() {
      return {
        isLoading: false,
        hasData: false,
        tableHeader: [
          { prop: "name", label: "客户名称", center: 'left' ,
          render: (h, data) => {
              return (
                <span>
                  {data.row.name}
                </span>
              );
            },
          },
          {prop:'taxId',label:'纳税人识別号',center: 'left',},
          {prop:'email',label:'邮箱',center: 'left',},
         
          {
            label: "操作",
            center: 'center',
            width:'120',
            render: (h, data) => {
              // if(data.row.isDefault == '1'){
              //   return (
              //     <span>
              //       <el-button class='button'  type="text" onClick={() => { this.edit(data.row);}}>
              //         编辑
              //       </el-button>
              //     </span>
              //   );
              // }else{
                return (
                  <span>
                    <el-button class='btn' type="text" v-auth="customerInfo:edit" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                      <el-button class='btn' type="text" v-auth="customerInfo:delete" onClick={() => { this.delete(data.row);}}>
                        删除
                      </el-button>
                  </span>
                );
              // }
            },
          },
        ],
        // queryValue:'',
        // tableData: [],
        // total: 0,
        // // pageSize:10,
        // page: 1,
        // rows: 20,
        // form: {
        //     page: 1,
        //     rows: 20,
        //   },
        // pageSizeList: [20, 50, 100, 200],
        // isdeling: false,
        // multipleSelection:[],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 20,
        isdeling: false,
        multipleSelection:[],

        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        // 保险id
        id: '',

        delDialog: false,
        importVis: false,
        exportVis:false,
        // exportType:'2',

      }
    },
    methods: {
      //  getInitList(val) {  //设置当前页
      //   this.page = val;
      //   this.initTable(val);//修改分页的条数后，显示当前页的数据
      // },
      // changeSize(val) { //设置分页条数
      //   this.rows = val;
      //   this.initTable();
      // },
      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      //--------------导出------------------
      // selectType(value){
      //   this.exportType = value
      // },
      confirmExport(type){
        // if(this.$refs.booksTable.total === 0){
        //   einvAlert.warning('提示','当前查询条件无数据，请检查',true,'确定')
        //   return;
        // }

        if(type === '1' && this.multipleSelection.length === 0){
          einvAlert.warning('提示','请先勾选',true,'确定')
          this.isdeling = false
          this.exportVis = false
          return
        }
        axios({
          method: 'get',
          url: '/api/einvoice/customer/export',
          responseType: 'blob',
          params: {
            companyId: this.getToken('companyId'),
            ids: type === '1'?this.id:'',
            exportType: type,
            // ...this.$refs.booksTable.exportForm
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '客户信息' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
          this.exportVis = false
        }).catch(err=>{
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })
        this.exportVis=false;
      },

      //---------新增----------
      add() {
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.$router.push('customerInfo/add')
      },
      //---------编辑----------
      edit(row) {
        this.$router.push({
          path: 'customerInfo/edit',
          query: {
            id: row.id
          }
        })
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
        this.id = this.multipleSelection.map(item => {
                  return item.id
                }).join(',')
      },

      //批量删除
      selectDel(){
        if (this.multipleSelection.length == 0) {
          einvAlert.error("提示",'请选中需要删除的数据！')
        } else{
          this.delDialog = true
          this.id = this.multipleSelection.map(item => {
                  return item.id
                }).join(',')
        }
        // console.log( this.id,' this.id')
      },
      //单选删除
      delete(row) {
        this.delDialog = true
        this.id = row.id
      },
      cancelDialog() { this.delDialog = false },

      confirmDialog() {
        this.isdeling = true
        let data = { companyId: this.getToken('companyId'),ids: this.id, };
           this.$store.dispatch("delCustomer", data).then(res => {
            this.isLoading = false;
           if (res.success) {
              this.isLoading = false;
              this.isdeling = false;
              einvAlert.success("提示",'删除成功')
              this.delDialog = false
              this.initTable()
            } else {
               this.isLoading = false;
               this.isdeling = false;
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
        
      },
      
      
      // 客户信息管理列表
      initTable(current) {
        // this.isLoading = true;
        // this.form.rows = this.rows;
        // this.form.page = current ? current : this.page;
        let data = {
          name: this.queryValue, 
          companyId: this.getToken('companyId'),
          page: this.page,
          rows: this.pageSize, };
        this.$store.dispatch("FindCustomer", data).then(res => {
           if (res.success) {
             this.isLoading = false;
             this.tableData = res.data;
            //  this.total = res.data.length;
             this.total = res.totalCount;
            } else {
              this.isLoading = false;
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
               this.isLoading = false;
          })
      },

      imports(){
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
     handleExport(){
        // if (this.multipleSelection.length == 0) {
        //   einvAlert.warning('提示','请先勾选',true,'确定')
        //   return;
        // } 
        this.exportVis = true;
     },
      exportCancel(){
        this.exportVis = false;
      },
      showTips(){
        this.promptVisible = true;
      },
      tipsCancel(){
        this.promptVisible = false;
      }
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }
</style>
